import type { ChatPostMessageArguments } from '@slack/web-api';
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  getDocs,
  getDoc,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import type { NewCampaign, BuilderStep } from '@/components/Campaigns/types';
import { poll } from '@/utils/helpers';
import type { AsyncTask, AsyncTaskType } from 'types/AsyncTask';
import { AsyncTaskStatus, COMPLETED_TASK_STATUSES } from 'types/AsyncTask';
import type { ImportContactsTaskType } from 'types/Automation';
import { LinkedinStatus } from 'types/Automation';
import type {
  Campaign,
  CampaignContactStateFilter,
  CampaignFilter,
  CampaignType,
  CampaignWithSequence,
  GetCampaignListReturn,
  GetContactsByCampaignReturn,
} from 'types/Campaign';
import type { CampaignContactState } from 'types/Campaign';
import type {
  Contact,
  ContactFilter,
  DisplayableContactAction,
  NewContact,
} from 'types/Contact';
import type { EnrichmentLevel, EnrichmentType } from 'types/ContactEnrichment';
import type { Customer, DuplicateContactRules } from 'types/Customer';
import type {
  ExternalJob,
  Integration,
  IntegrationCRMContactType,
  IntegrationStatus,
  IntegrationType,
  IntegrationUpdateValues,
} from 'types/Integration';
import type { ManualTask, ManualTaskFilter } from 'types/ManualTask';
import type { Paging } from 'types/Paging';
import type {
  PersonalizedMessage,
  PersonalizedMessageFilter,
  PersonalizedMessageUpdate,
} from 'types/PersonalizedMessage';
import type { TemplateWithSteps } from 'types/Template';
import type { User, UserListQuery, UserRole, UserStatus } from 'types/User';
import getStripe from './lib/stripe';
import { axiosWithToken } from './utils/axiosWithToken';
import { firebaseApp, firebaseDb } from './utils/firebase';

export const getCustomer = async ({
  customerId,
}: {
  customerId: number | 'current';
}): Promise<Customer | null> => {
  const response = await axiosWithToken.get(`/api/customers/${customerId}`);
  const { customer } = response.data;
  return customer || null;
};

export const updateCustomer = async ({
  customerId,
  ...updateValues
}: {
  customerId: number | 'current';
} & Partial<Omit<Customer, 'customerId'>>): Promise<void> => {
  await axiosWithToken.put(`/api/customers/${customerId}`, updateValues);
};

export interface NewUserArgs {
  customerId: number;
  name: string;
  email: string;
  role: UserRole;
  status: UserStatus;
}

export const createUser = async ({
  user,
}: {
  user: NewUserArgs;
}): Promise<number> => {
  const { data } = await axiosWithToken.post(`/api/users`, {
    ...user,
    linkedinStatus: 'NEW',
  });
  return data.userId;
};

/** file://./../pages/api/users/[userId].ts */
export const getUser = async ({
  userId,
}: {
  userId: number;
}): Promise<User | null> => {
  const response = await axiosWithToken.get(`/api/users/${userId}`);
  const { user } = response.data;

  if (!user) {
    return null;
  }

  return user;
};

/** file://./../pages/api/users.ts */
export const getUserList = async (params: UserListQuery): Promise<User[]> => {
  const response = await axiosWithToken.get(`/api/users`, { params });
  const { users } = response.data;
  return users;
};

/** file://./../pages/api/users.ts */
export const getCurrentUser = async (): Promise<User | null> => {
  const response = await axiosWithToken.get(`/api/users?current=true`);
  const { user } = response.data;

  if (!user) {
    return null;
  }

  const isStripeUser = await isStripeConnected({ authId: user?.authId });

  return { ...user, isStripeUser };
};

export const attemptActivation = async (data: {
  authId: string;
  name?: string;
}): Promise<User> => {
  const response = await axiosWithToken.put(`/api/users/activate`, data);
  const { user } = response.data;
  return user;
};

export const getOrCreateUser = async ({
  authId,
  name,
}: {
  authId: string;
  name?: string;
}): Promise<User | null> => {
  try {
    const existingUser = await getCurrentUser();
    if (existingUser) {
      return existingUser;
    }

    const activationResult = await attemptActivation({ authId, name });
    if (activationResult) {
      await updateUserAgent({ userId: activationResult.userId });
    }
    return activationResult;
  } catch (error) {
    return null; // null user returned only on error
  }
};

export const updateUser = async ({
  userId,
  ...updateValues
}: {
  userId: number;
} & Partial<Omit<User, 'userId'>>): Promise<void> => {
  await axiosWithToken.put(`/api/users/${userId}`, {
    ...updateValues,
  });
};

export const updateUserAgent = async ({
  userId,
}: {
  userId: number;
}): Promise<void> => {
  await axiosWithToken.put(`/api/users/${userId}/updateUserAgent`);
};

/** file://./../pages/api/integrations.ts */
export const createIntegration = async ({
  connectionId,
  crmContactType,
  type,
}: {
  connectionId: string;
  crmContactType?: IntegrationCRMContactType;
  type: IntegrationType;
}): Promise<void> => {
  await axiosWithToken.post(`/api/integrations`, {
    connectionId,
    crmContactType,
    type,
  });
};

export const getIntegrationList = async (
  params: {
    status?: IntegrationStatus;
    type?: IntegrationType;
  } = {}
): Promise<Integration[]> => {
  const response = await axiosWithToken.get(`/api/integrations`, { params });
  return response.data.integrations;
};

export const getIntegration = async ({
  integrationId,
}: {
  integrationId: number;
}): Promise<Integration> => {
  const response = await axiosWithToken.get(
    `/api/integrations/${integrationId}`
  );
  return response.data.integration;
};

export const updateIntegration = async ({
  integrationId,
  ...updateValues
}: {
  integrationId: number;
} & IntegrationUpdateValues): Promise<void> => {
  await axiosWithToken.patch(`/api/integrations/${integrationId}`, {
    ...updateValues,
  });
};

/** file://./../pages/api/campaigns.ts */
export const createCampaign = async ({
  campaign,
}: {
  campaign: NewCampaign;
}): Promise<number> => {
  const { data } = await axiosWithToken.post(`/api/campaigns`, {
    ...campaign,
  });
  const { campaignId } = data;
  return campaignId;
};

/** file://./../pages/api/campaigns/[campaignId].ts */
export const getCampaign = async ({
  campaignId,
  contactId,
}: {
  campaignId: number;
  contactId?: number;
}): Promise<CampaignWithSequence> => {
  const url = contactId
    ? `/api/campaigns/${campaignId}?contactId=${contactId}`
    : `/api/campaigns/${campaignId}`;

  const response = await axiosWithToken.get(url);
  const { campaign } = response.data;
  return campaign;
};

/** file://./../pages/api/campaigns.ts */
export const getCampaignList = async ({
  filters,
  paging,
  includeStats,
}: {
  filters?: CampaignFilter;
  paging?: Paging;
  includeStats?: boolean;
}): Promise<GetCampaignListReturn> => {
  const response = await axiosWithToken.get(`/api/campaigns`, {
    params: { filters, paging, includeStats },
  });

  return response.data;
};

export const getAdminCampaignList = async (): Promise<
  CampaignWithSequence[]
> => {
  const response = await axiosWithToken.get(`/api/admincampaigns`);
  const { campaigns } = response.data;
  return campaigns;
};

export const getPastCampaignList = async (): Promise<
  CampaignWithSequence[]
> => {
  const response = await axiosWithToken.get(`/api/pastcampaigns`);
  const { campaigns } = response.data;
  return campaigns;
};

export interface CampaignUpdateValues extends Campaign {
  steps: BuilderStep[];
}

/** file://./../pages/api/campaigns/[campaignId].ts */
export const updateCampaign = async ({
  campaignId,
  contactId = null,
  ...campaignUpdateValues
}: {
  campaignId: number;
  contactId?: number | null;
} & Partial<CampaignUpdateValues>): Promise<void> => {
  const url = contactId
    ? `/api/campaigns/${campaignId}?contactId=${contactId}`
    : `/api/campaigns/${campaignId}`;

  await axiosWithToken.put(url, { ...campaignUpdateValues });
};

export const deleteCampaign = async ({
  campaignId,
}: {
  campaignId: number;
}): Promise<boolean> => {
  try {
    await axiosWithToken.delete(`/api/campaigns/${campaignId}`);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/** file://./../pages/api/campaigns/[campaignId]/contacts.ts */
export const getCampaignContactList = async ({
  campaignId,
  filters,
  paging,
}: {
  campaignId: number;
  filters?: CampaignContactStateFilter;
  paging?: Paging;
}): Promise<GetContactsByCampaignReturn> => {
  const response = await axiosWithToken.get(
    `/api/campaigns/${campaignId}/contacts`,
    { params: { paging, filters } }
  );
  return response.data;
};

export const deleteCampaignContacts = async ({
  campaignId,
  contactIds,
}: {
  campaignId: number;
  contactIds: number[];
}): Promise<boolean> => {
  try {
    await axiosWithToken.patch(`/api/campaigns/${campaignId}/contacts`, {
      contactIds,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/** file://./../pages/api/campaigns/[campaignId]/contacts.ts */
export const createContacts = async ({
  campaignId,
  contacts,
}: {
  campaignId: number;
  contacts: NewContact[];
}): Promise<{ numberOfContactsAdded: number }> => {
  const response = await axiosWithToken.post(
    `/api/campaigns/${campaignId}/contacts`,
    { contacts }
  );
  return response.data;
};

export const updateCampaignContact = async ({
  campaignId,
  campaignContactStateId,
  ...contactUpdateValues
}: {
  campaignId: number;
  campaignContactStateId: number;
} & Partial<CampaignContactState>): Promise<void> => {
  await axiosWithToken.put(
    `/api/campaigns/${campaignId}/contacts/${campaignContactStateId}`,
    { ...contactUpdateValues }
  );
};

/** file://./../pages/api/contacts.ts */
export const getContactList = async ({
  filters,
}: {
  filters?: ContactFilter;
}): Promise<{ contacts: Contact[] }> => {
  const response = await axiosWithToken.get(`/api/contacts`, {
    params: { filters },
  });

  return response.data;
};

export const updateContact = async ({
  contactId,
  ...contactUpdateValues
}: {
  contactId: number;
} & Partial<Contact>): Promise<void> => {
  await axiosWithToken.put(`/api/contacts/${contactId}`, {
    ...contactUpdateValues,
  });
};

/** file://./../pages/api/contacts/[contactId]/contactActions.ts */
export const getContactActionList = async ({
  contactId,
}: {
  contactId: number;
}): Promise<DisplayableContactAction[]> => {
  const response = await axiosWithToken.get(
    `/api/contacts/${contactId}/contactActions`,
    {}
  );

  return response.data;
};

export const createTemplate = async (template: {
  name: string;
  sequence: BuilderStep[];
  type: CampaignType;
}) => {
  const { data } = await axiosWithToken.post(`/api/templates`, {
    ...template,
  });

  const { templateId } = data;

  return templateId;
};

export const getTemplateList = async (params: {
  type?: CampaignType;
}): Promise<TemplateWithSteps[]> => {
  const response = await axiosWithToken.get(`/api/templates`, { params });
  const { templates } = response.data;
  return templates;
};

export const updateAsyncTask = async ({
  asyncTaskId,
  ...updateValues
}: {
  asyncTaskId: number;
} & Partial<AsyncTask>) => {
  const response = await axiosWithToken.put(`/api/asyncTasks/${asyncTaskId}`, {
    ...updateValues,
  });
  return response.data;
};

export const getAsyncTask = async ({
  asyncTaskId,
}: {
  asyncTaskId: number;
}): Promise<AsyncTask> => {
  const response = await axiosWithToken.get(`/api/asyncTasks/${asyncTaskId}`);

  const { asyncTask } = response.data;

  return asyncTask;
};

export const getAsyncTaskList = async (params: {
  campaignId?: number;
  types?: AsyncTaskType[];
  statuses?: AsyncTaskStatus[];
}) => {
  const response = await axiosWithToken.get(`/api/asyncTasks`, { params });

  const { tasks } = response.data;

  return tasks as AsyncTask[];
};

export const pollForAsyncTaskComplete = async ({
  asyncTaskId,
}: {
  asyncTaskId: number;
}) => {
  const validate = (asyncTask: AsyncTask) =>
    !COMPLETED_TASK_STATUSES.includes(asyncTask.status);

  const asyncTask = await poll(
    () => getAsyncTask({ asyncTaskId }),
    validate,
    5000
  );

  return asyncTask;
};

export const connectAccount = async ({
  userId,
  email,
  password,
}: {
  userId: number;
  email: string;
  password: string;
}) => {
  const { data } = await axiosWithToken.post(`/connectAccount`, {
    userId,
    email,
    password,
  });

  const { asyncTaskID } = data;

  return asyncTaskID;
};

export const pollForConnectAccountProgress = async ({
  asyncTaskId,
}: {
  asyncTaskId: number;
}) => {
  const validate = (asyncTask: AsyncTask) =>
    asyncTask.status !== AsyncTaskStatus.COMPLETE &&
    asyncTask.status !== AsyncTaskStatus.ERROR &&
    asyncTask.status !== AsyncTaskStatus.WAITING_FOR_INPUT;

  const asyncTask = await poll(
    () => getAsyncTask({ asyncTaskId }),
    validate,
    5000
  );

  const taskData = asyncTask.taskData;

  const tokenAndInputRequired =
    asyncTask.status === AsyncTaskStatus.WAITING_FOR_INPUT
      ? 'requiresInput'
      : taskData?.token;

  return {
    token: tokenAndInputRequired,
    asyncTaskId,
    linkedinStatus: taskData?.linkedinStatus ?? 'ERROR_UNKNOWN',
  };
};

export const connectAccountWithSecretKey = async ({
  userId,
  email,
  password,
  secretKey,
}: {
  userId: number;
  email: string;
  password: string;
  secretKey: string;
}) => {
  const { data } = await axiosWithToken.post(`/connectAccount`, {
    userId,
    email,
    password,
    authenticationCode: secretKey,
  });

  const { asyncTaskID } = data;

  const validate = (asyncTask: AsyncTask) =>
    asyncTask.status !== AsyncTaskStatus.COMPLETE &&
    asyncTask.status !== AsyncTaskStatus.ERROR &&
    asyncTask.status !== AsyncTaskStatus.WAITING_FOR_INPUT;

  const asyncTask = await poll(
    () => getAsyncTask({ asyncTaskId: asyncTaskID }),
    validate,
    5000
  );

  const taskData = asyncTask.taskData;

  return {
    linkedinStatus: taskData?.linkedinStatus ?? LinkedinStatus.ERROR_UNKNOWN,
  };
};

export const queueImportContacts = async ({
  campaignId = null,
  importRules,
  name,
  projectUrl,
  type,
  userId,
}: {
  campaignId?: number | null;
  importRules?: DuplicateContactRules;
  name: string;
  projectUrl: string;
  type: ImportContactsTaskType;
  userId: number;
}) => {
  const { data } = await axiosWithToken.post(`/importContacts`, {
    campaignId,
    importRules,
    name,
    projectUrl,
    type,
    userId,
  });

  const { asyncTaskId } = data;

  return { asyncTaskId };
};

export const getFilteredContacts = async ({
  campaignId,
  contacts,
  importRules,
}: {
  campaignId: number;
  contacts: NewContact[];
  importRules?: DuplicateContactRules;
}): Promise<(NewContact | Contact)[]> => {
  const response = await axiosWithToken.post(`/api/contacts/filterDuplicates`, {
    campaignId,
    contacts,
    importRules,
  });
  return response.data.contacts;
};

export const startCampaign = async ({
  userId,
  campaignId,
}: {
  userId: number;
  campaignId: number;
}) => {
  await axiosWithToken.post(`/sendCampaign`, {
    userId,
    campaignId,
  });
};

/*** Stripe and external API calls below ***/

export const isStripeConnected = async ({
  authId,
}: {
  authId?: string;
}): Promise<boolean> => {
  if (!authId) {
    return false;
  }

  const userRef = doc(firebaseDb, 'users', authId);
  const userSnapshot = await getDoc(userRef);
  const firebaseUser = userSnapshot.data();

  return !!firebaseUser?.stripeId;
};

interface StripeSubscription {
  items: [
    {
      price: {
        id: string;
      };
    },
  ];
  status: string;
  quantity: number;
}

export const getStripeSubscription = async ({
  authId,
}: {
  authId: string;
}): Promise<StripeSubscription | null> => {
  const subscriptionsRef = collection(
    firebaseDb,
    'users',
    authId,
    'subscriptions'
  );

  const subscriptionSnapshot = await getDocs(subscriptionsRef);

  let stripeSubscription: StripeSubscription | null = null;
  subscriptionSnapshot.forEach((doc) => {
    const subscription = doc.data() as StripeSubscription;

    if (
      subscription.status === 'active' &&
      subscription.items[0].price.id === process.env.NEXT_PUBLIC_PRICE_ID
    ) {
      stripeSubscription = subscription;
    }
  });

  return stripeSubscription;
};

export const createCheckoutSession = async (uid: string) => {
  const checkoutSessionsRef = collection(
    firebaseDb,
    'users',
    uid,
    'checkout_sessions'
  );

  const checkoutSessionRef = await addDoc(checkoutSessionsRef, {
    line_items: [
      {
        price: process.env.NEXT_PUBLIC_PRICE_ID,
        adjustable_quantity: {
          enabled: true,
          minimum: 1,
          maximum: 10,
        },
        quantity: 1,
      },
    ],
    allow_promotion_codes: true,
    success_url: `${window.location.origin}/profile?tab=billing&signup=success`,
    cancel_url: `${window.location.origin}/profile`,
  });

  onSnapshot(checkoutSessionRef, async (snap) => {
    const { sessionId } = snap.data() || { sessionId: null };
    if (sessionId) {
      const stripe = await getStripe();

      stripe.redirectToCheckout({ sessionId });
    }
  });
};

export const goToBillingPortal = async () => {
  const functions = getFunctions(firebaseApp, 'us-central1');
  const createPortalLink = httpsCallable<unknown, { url: string }>(
    functions,
    'ext-firestore-stripe-payments-createPortalLink'
  );

  const { data } = await createPortalLink({
    returnUrl: `${window.location.origin}/profile`,
  });

  window.location.assign(data.url);
};

export const updateInSlack = async ({
  channelCode,
  email,
  blocks,
  message,
}: {
  channelCode?: string;
  email?: string;
  blocks?: ChatPostMessageArguments['blocks'];
  message?: string;
}): Promise<void> => {
  await axiosWithToken.post(
    '/api/updateinslack',
    {
      channelCode,
      email,
      blocks,
      message,
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    }
  );
};

export const enrichContactsForCampaign = async ({
  campaignId,
  contactIds,
  enrichmentType,
  enrichmentLevel,
}: {
  campaignId: number;
  contactIds: number[];
  enrichmentType: EnrichmentType;
  enrichmentLevel: EnrichmentLevel;
}) => {
  let totalEmailsFound = 0;
  let totalPhoneNumbersFound = 0;
  let asyncTaskId: number | null = null;

  try {
    const { data } = await axiosWithToken.post(
      `api/campaigns/${campaignId}/contacts/enrich`,
      { contactIds, enrichmentType: enrichmentType || null, enrichmentLevel }
    );

    asyncTaskId = data.asyncTaskId;

    while (asyncTaskId) {
      const asyncTask = await pollForAsyncTaskComplete({ asyncTaskId });

      if (asyncTask.status === AsyncTaskStatus.ERROR) {
        throw new Error(asyncTask.taskData.errorMessage);
      }

      if (asyncTask.status === AsyncTaskStatus.COMPLETE) {
        const { nextTaskId, numberOfEmailsFound, numberOfPhoneNumbersFound } =
          asyncTask.taskData;

        totalEmailsFound += numberOfEmailsFound;
        totalPhoneNumbersFound += numberOfPhoneNumbersFound;

        asyncTaskId = nextTaskId;
      }
    }
  } catch (error) {
    throw new Error(
      `Contact enrichment failed for asyncTaskId-${asyncTaskId}: ${(error as Error).message}`
    );
  }

  return {
    hasError: false,
    numberOfEmailsFound: totalEmailsFound,
    numberOfPhoneNumbersFound: totalPhoneNumbersFound,
  };
};

export const syncContactsWithIntegration = async ({
  campaignId,
  contactIds,
  integrationId,
}: {
  campaignId: number;
  contactIds: number[];
  integrationId: number;
}) => {
  const { data } = await axiosWithToken.post(`api/externalContacts/sync`, {
    campaignId,
    contactIds,
    integrationId,
  });

  const { asyncTaskId } = data;
  const asyncTask = await pollForAsyncTaskComplete({ asyncTaskId });

  if (asyncTask.status === AsyncTaskStatus.ERROR) {
    throw new Error(asyncTask.taskData.errorMessage);
  }
};

export const getExternalCampaigns = async ({
  integrationType,
}: {
  integrationType: IntegrationType;
}): Promise<ExternalJob[]> => {
  const response = await axiosWithToken.get(
    `api/externalCampaigns?integrationType=${integrationType}`
  );

  return response.data.externalCampaigns as ExternalJob[];
};

export const exchangeNylasCodeForToken = async ({ code }: { code: string }) => {
  const response = await axiosWithToken.post(`nylas/exchangeCodeForToken`, {
    code,
  });

  return response.status === 200;
};

/** file://./../pages/api/personalizedMessages.ts */
export const getPersonalizedMessages = async ({
  filters,
}: {
  filters: PersonalizedMessageFilter;
}): Promise<PersonalizedMessage[]> => {
  const response = await axiosWithToken.get(`/api/personalizedMessages`, {
    params: { filters },
  });

  return response.data;
};

/** file://./../pages/api/campaigns/[campaignId]/contacts/personalize.ts */
export const createPersonalizedMessagesForContacts = async ({
  campaignId,
  contactIds,
}: {
  campaignId: number;
  contactIds: number[];
}): Promise<{
  numberPersonalizedPerContact: number;
  personalizedMessageIds: number[];
}> => {
  const response = await axiosWithToken.post(
    `/api/campaigns/${campaignId}/contacts/personalize`,
    { campaignId, contactIds }
  );

  const { asyncTaskId } = response.data;

  const asyncTask = await pollForAsyncTaskComplete({ asyncTaskId });

  if (asyncTask.status === AsyncTaskStatus.ERROR) {
    throw new Error(asyncTask.taskData.errorMessage);
  }

  const { numberPersonalizedPerContact, personalizedMessageIds } =
    asyncTask.taskData;

  return {
    numberPersonalizedPerContact: numberPersonalizedPerContact || 0,
    personalizedMessageIds: personalizedMessageIds || [],
  };
};

/** file://./../pages/api/personalizedMessages.ts */
export const updatePersonalizedMessages = async ({
  updateArray,
}: {
  updateArray: PersonalizedMessageUpdate[];
}) => {
  const response = await axiosWithToken.patch(`/api/personalizedMessages`, {
    updateArray,
  });

  return response.data;
};

/** file://./../pages/api/personalizedMessages/accept.ts */
export const acceptPersonalizedMessages = async ({
  sequenceId,
  campaignId,
  contactIds,
}: {
  sequenceId: number;
  campaignId: number;
  contactIds: number[];
}): Promise<void> => {
  const response = await axiosWithToken.patch(
    `/api/personalizedMessages/accept`,
    { campaignId, contactIds, sequenceId }
  );

  return response.data;
};

/** file://./../pages/api/manualTasks.ts */
export const getManualTasks = async ({
  filters,
}: {
  filters: ManualTaskFilter;
}): Promise<ManualTask[]> => {
  const response = await axiosWithToken.get(`/api/manualTasks`, {
    params: { filters },
  });

  return response.data;
};
